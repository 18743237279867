// DIALOGUE

.modal{
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_wrapper{
  height: 100%;
    width: 100%;
    max-width: 950px;
    max-height: 650px;
  background-color: #ffffff;
  padding: 3rem;
  box-sizing: border-box;
  display: flex;
    align-items: center;
    justify-content: center;
}

.modal_wrapper > div{
    width: 100%;
}

.modal_choice-button{
  display: flex;
  justify-content: space-evenly;
}

.modal_choice-button > div{
  flex: 1 0 auto;
  transition: 0.2 opacity;
}
.modal_choice-button > div:hover{
  opacity: 0.8;
}
.modal_choice-button > div[type="yes"]{
  background-color: $primary-color;
}
.modal_choice-button > div[type="no"]{
  background-color: $dark-grey;
}

.modal_title{
  font-size: 1.7rem;
  font-family: $primary-font;
  color: $primary-color;
  font-weight: 700;
  margin: 15px auto;
  text-align: center;
  margin: 15px auto;
}

.modal_subtitle{
  width: 95%;
  margin-bottom: 0px;
  font-family: $secondary-font;
  color: $text-color;
  font-size: 0.95rem;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.modal_subtitle b {
  font-size: 1.1rem;
  text-align: center;
  position: relative;
  top: 1rem;
}
.modal_text-container{
  min-height: 155px;
  margin: 0 10px;
}

// HOURS
.wrapper{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px 27px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}

.subtitle{
  width: 95%;
  margin: 1.5rem auto 0 auto;
  color: $white;
  font-family: $secondary-font;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
/*
.hours_button{
    display: flex;
    width: 100px;
    height: 45px;
    margin: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #d8efd2;
    font-family: $secondary-font;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}

.hours_button.free{
  background-color: #eaeaea;
  cursor: unset;
  color: #cac4c4;
}

.hours_button.active{
  background-color: #e30613;
  color: #fff;
}
*/

.wrapper > div:focus{
  outline: none;
}

.wrapper p {
  color: $primary-color;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
}


.selected-day{
  display: flex;
  width: 100%;
  height: 80px;
  font-size: 1.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primary-color;
  font-family: $secondary-font;
  color: $white;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  margin: 0;
  border-bottom: 2px solid #fff;
}

.box{
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 700px;
  min-height: 550px;
  min-width: 800px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 auto;
  background-color: $dark-grey;
}

.box-wrapper{
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 350px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


// WEEKS

.choice-wrapper{
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 100%;
height: 100px;
padding: 0px 2px;
align-items: center;
border-bottom: 1px solid #d1d1d1;
background-color: #f7f7f7;
justify-content: center;
}

.choice-wrapper .arrows{
  width: 30px;
  height: 30px;
  -webkit-appearance: inherit;
}

.choice-wrapper .arrows:focus{
  outline: none;
}

.pagination{
display: flex;
width: 80%;
height: 80px;
margin: 1.5rem 0 0 0;
justify-content: center;
}

.pagination > div {
  background: transparent;
  border: none;
  -webkit-appearance: inherit;
}

.pagination > div:focus{
  outline: none;
}