.apartment-size-container {
  @include boxSpecs(null, 100%, null, flex, null);
}
.SizeBox {
  @include boxSpecs($white, 100%, 100%, flex, null);
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.SizeBox:nth-child(odd) {
  background-color: lighten($light-grey, 55%);
}
.SizeBox .size-icon {
  @include image(64px, 64px, 0 auto, '../../img/icons/home__size--01.svg');
  display: block;
}
.SizeBox h2 {
  @include typo(null, 1.5rem, $dark-grey, null, null, null, uppercase);
  width: 100%;
}
.SizeBox h5 {
  @include typo($primary-font, 1.2rem, null, null, null, null, null);
  margin: 0;
}
.SizeBox hr {
  @include boxSpecs($primary-color, 10%, 3px, block, null);
  margin: 2rem 45%;
  border: 0;
}
.SizeBox .primary-button {
  margin: 0 auto;
}

@media screen and (max-width: $tablet) {
  .apartment-size-container {
    flex-wrap: wrap;
  }
  .SizeBox {
    height: 400px;
  }
}