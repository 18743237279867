.apartment-planimetry-container {
  @include boxSpecs(null, 100%, null, flex, null);
  flex-wrap: wrap;
  margin: 0 0 3rem 0;
}
.PlanimetryBox {
  @include boxSpecs($white, null, null, flex, null);
  max-width: 47%;
  min-height: 320px;
  flex: 1 0 30%;
  margin: 3rem 1.5rem 0 1.5rem;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid $black;
  box-shadow: 0px 0px 8px 4px #f3f3f3;
}
.planimetry-info {
  @include typo($secondary-font, null, null, null, center, null, null);
  background-color: $dark-grey;
  flex: 0 0 35%;
  max-width: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.planimetry-image {
  @include image(null, null, null, null);
  flex: 0 0 65%;
  //background-size: 90%;
}
.planimetry-info h2 {
  @include typo(null, 2rem, $white, null, null, null, uppercase);
  width: 100%;
  margin: 0.2rem 0;
}
.planimetry-info h5 {
  @include typo(null, 0.9rem, $primary-color, normal, null, 0.05em, uppercase);
  width: 100%;
  margin: 0;
  padding: 0;
  &.mq {
    text-transform: none;
  }
  &.price {
    font-family: $secondary-font;
    letter-spacing: 0.1em;
    color: $white;
    margin: 1rem 0 0 0;
  }
  &.rate {
    font-family: $secondary-font;
    color: $white;
    font-size: 0.78rem;
    margin: 1rem 0 0 0;
  }
}
.planimetry-info hr {
  @include boxSpecs($primary-color, 10%, 3px, block, null);
  margin: 1rem 45%;
  border: 0;
}
.planimetry-info hr.variant-2{
  @include boxSpecs($white, 10%, 2px, block, null);
  margin: 0.5rem 45% 0 45%;
  border: 0;
}
.planimetry-info .primary-button {
  background-color: $primary-color;
  margin: 1rem auto;
  cursor: pointer;
  &:hover {
    background-color: rgba($primary-color, 0.7);
  }
}

@media screen and (max-width: $tablet) {
  .PlanimetryBox {
    max-width: 100%;
    flex-basis: calc(100% - 3rem);
  }
  .planimetry-image {
    background-size: 50%;
  }
}

@media screen and (max-width: $mobile) {
  .planimetry-image {
    flex: 0 0 50%;
  }
  .planimetry-image {
    background-size: 70%;
  }
}

// PLANIMETRY MODAL
.ReactModal__Content {
  @include boxSpecs(null, 100%, 100%, null, null);
  max-width: 1100px;
  max-height: 600px;
  @extend %center-absolute-XY;
}
.ReactModal__Content .PlanimetryBox {
  flex: 0 0 100%;
  height: 100%;
  max-width: inherit;
  margin: 0;
  padding: 3px;
}
.ReactModal__Content .modal-close {
  @include image(20px, 20px, 0, '../../img/icons/modal__close.svg');
  @include position (1.5rem, 1.5rem, null, null);
  background-size: cover;
  position: absolute;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.ReactModal__Content {
  @include position (1rem, 1rem, null, null);
  position: absolute;
  overflow: visible !important;
}
.ReactModal__Content .planimetry-info {
  flex: 0 0 35%;
  max-width: inherit;
}
.ReactModal__Content .planimetry-image {
  flex: 0 0 65%;
}
.ReactModal__Content h5.price{
  font-size: 1.3rem;
  font-weight: bold;
}
.ReactModal__Content h5.availability-numbers,
.ReactModal__Content h5.quantity {
  color: $white;
}
.ReactModal__Content .availability {
  text-transform: none;
  margin: 2rem 0 0.5rem 0;
}
.services {
  @include boxSpecs(null, 100%, null, flex, null);
  justify-content: space-evenly;
  margin: 3rem 0 2rem 0;
}
.services > div {
  font-family: $secondary-font;
  position: relative;
}
.services > div:before {
  content: "";
  @include image(32px, 32px, 0, '../../img/icons/modal-planimetry__bath.svg');
  background-size: cover;
  display: block;
  margin: 0 auto 0.2rem auto;
}
.services > div.rooms:before {
  background-image: url(../../img/icons/modal-planimetry__rooms.svg);
}
.services > div.loggia:before {
  background-image: url(../../img/icons/modal-planimetry__loggia.svg);
}
.ReactModal__Content .modal-arrow {
  content: "";
  @include boxSpecs(null, 0, 0, null, absolute);
  @include position (null, -40px, null, null);
  border-style: solid;
  border-width: 18px 0 18px 29px;
  border-color: transparent transparent transparent #d97c50;
  @extend %center-absolute-Y;
  transform-origin: 0 0;
}
.ReactModal__Content .modal-arrow.back {
  @include position (null, inherit, null, -10px);
  transform: rotate(180deg) translateY(-50%);
}

@media screen and (max-width: $tablet) {
  .ReactModal__Content {
    max-width: 90%;
  }
  .ReactModal__Content .PlanimetryBox {
    min-width: 100%;
    flex-wrap: wrap;
    overflow: auto;
  }
  .ReactModal__Content .PlanimetryBox > div {
    flex: 0 0 100%;
    min-height: 350px;
  }
  .ReactModal__Content .PlanimetryBox .planimetry-info {
    min-width: 100%;
    order: 2;
    padding: 2rem 0;
  }
  .ReactModal__Content .modal-arrow {
    @include position (null, 0, null, null);
  }
  .ReactModal__Content .modal-arrow.back {
    @include position (null, null, null, 10px);
  }
}