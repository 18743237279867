.recap-container {
  @include boxSpecs($dark-grey, 100%, null, flex, null);
  max-width: 1400px;
  margin: 1rem auto;
  border: 1px solid $dark-grey;
  box-shadow: 0px 0px 0px 5px lighten($light-grey, 55%);
}
.recap-title {
  @include typo($secondary-font, 1.3rem, $dark-grey, bold, center, null, none);
  margin: 0 auto;
  padding: 1.5rem 0 0.5rem 0;
  display: block;
}
.recap-container > div {
  flex: 0 0 25%;
}
.info-side .PlanimetryBox {
  box-shadow: none;
  border: 0;
  padding: 0;
}
.recap-container .planimetry-side {
  flex: 0 0 50%;
}
.recap-container .info-side .PlanimetryBox {
  background-color: $dark-grey;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
}
.recap-container .info-side .PlanimetryBox .services{
  margin: 2.5rem 0 0 0;
}
.recap-container .info-side .furnishings {
  width: inherit;
  padding: 0;
  margin: 0 1rem;
  &.Easy .furnishing-box {
    background: linear-gradient(rgba($black, 0.7), rgba($black, 0.7)),
      url('https://tender-homeconfigurator.lokstadt.ch/img/virtual/Living/easy/Lockstadt_Living_SmartPack_01_RainForest_rev03.jpg');
  }
  &.Comfort .furnishing-box {
    background: linear-gradient(rgba($black, 0.7), rgba($black, 0.7)),
      url('https://tender-homeconfigurator.lokstadt.ch/img/virtual/Living/comfort/Lockstadt_Living_Upgrade2_01_WildForest_rev01.jpg');
  }
  &.Premium .furnishing-box {
    background: linear-gradient(rgba($black, 0.7), rgba($black, 0.7)),
      url('https://tender-homeconfigurator.lokstadt.ch/img/virtual/Living/premium/Lockstadt_Living_Upgrade1_01_RainForest_rev01.jpg');
  }
}
.recap-container .info-side .furnishing-box {
  @include position(null, null, null, 0);
  @include image(100%, 100%, null, null);
  background-color: rgba($black, 0.1);
  background-size: cover !important;
  background-position: center !important;
  border: 1px solid $primary-color;
  box-shadow: none;
  box-sizing: border-box;
}
.recap-container .info-side .furnishing-box:after {
  content: none;
}
.recap-container .text-box {
  color: white;
  line-height: 1.3;
}
.recap-container .info-side .PlanimetryBox .planimetry-info {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.recap-container .info-side .PlanimetryBox .planimetry-info .quantity,
.recap-container .info-side .PlanimetryBox .planimetry-info .mq {
  color: $white;
}
.recap-container .planimetry-side {
  @include boxSpecs(null, null, null, null, relative);
}
.recap-container .planimetry-side .planimetry-image {
  @include image(100%, 100%, 0, '../../img/images/form-image.jpg');
  background-color: $white;
  background-size: 60%;
  background-position: top center;
}
.recap-container .planimetry-side .text-box {
  @include boxSpecs($primary-color, 350px, null, null, absolute);
  @include position(null, null, -40px, null);
  @include typo(null, null, null, bold, center, null, null);
  margin: 0;
  @extend %center-absolute-X;
  box-shadow: 0 0 0 3px $primary-color;
}
.recap-container .planimetry-side .price-box {
  @include boxSpecs($dark-grey, 350px, null, null, absolute);
  border: 1px solid $primary-color;
  text-align: center;
  padding: 0.7rem 1rem;
  box-sizing: content-box;
  @extend %center-absolute-X;
  //bottom: 40px;
  top: 60%;
  box-shadow: 0 0 0 3px $dark-grey;
}
.recap-container .planimetry-side .price-box h5 {
  @include typo(null, null, $primary-color, normal, null, null, null);
}
.recap-container .planimetry-side .price-box h2 {
  @include typo(null, null, $white, null, null, 0.1em, null);
  margin: 0;
}
.recap-container .planimetry-side .price-box hr {
  background-color: $primary-color;
  width: 20%;
  margin: 1rem auto;
}
.recap-container .planimetry-side .price-box .rate {
  @include typo($secondary-font, 0.85rem, $white, bold, null, null, null);
  margin: 1rem 0;
}
.recap-container .planimetry-side .primary-button {
  @include boxSpecs($primary-color, null, null, table, relative);
  margin: -25px auto 0 auto;
  text-transform: uppercase;
  &:hover {
    background-color: $dark-grey;
  }
}
.recap-container .choice-side {
  background: linear-gradient(rgba($black, 0.7), rgba($black, 0.7)),
      url('https://tender-homeconfigurator.lokstadt.ch/img/virtual/Living/easy/Lockstadt_Living_SmartPack_01_RainForest_rev03.jpg');
  background-position: center !important;
  background-size: cover !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: 2rem;
  box-sizing: border-box;
}
/*
.recap-container .choice-side .choice-plan h5 {
  background-color: transparent;
  color: $dark-grey;
  font-weight: 800;
} */
.recap-container .choice-side > div {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid $primary-color;
}
.recap-container .choice-side > div + div {
  margin-top: 1rem;
}
.recap-container .choice-side h5 {
  background-color: $primary-color;
  @include typo(null, 0.9rem, $white, normal, left, null, null);
  padding: 0.5rem;
  box-sizing: border-box;
}
.recap-container .custom-checkbox {
  margin: 0.5rem;
  position: absolute;
  opacity: 0;
  & + label {
    font-size: 0.95rem;
    color: $white;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 1rem 1rem 0.5rem 2rem;
    padding: 0;
  }
  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -2px;
    left: -30px;
    background: white;
    border: 1px solid $primary-color;
    box-shadow: inset 0px 0px 0px 2px $white;
  }
  // Box checked
  &:checked + label:before {
    background: $primary-color;
  }
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
}
label b.reserved {
  background-color: $primary-color;
  color: $text-color;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0 0.7rem;
  padding: 0.1rem 0.3rem;
  display: inline-block;
  border: 1px solid rgba($primary-color, 0.9);
  position: relative;
  transform: skewX(11deg);
  box-shadow: 2px 3px 7px $text-color;
  &:before{
      content: '';
      display: inline-block;
      height: 20px;
      width: 4px;
      margin: -5px 5px 0 0;
      position: absolute;
      top: 4px;
      left: -4px;
      transform: skewX(-16deg);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 17px 0 0 5px;
      border-color: transparent transparent transparent #905336;
  }
}

.recap-container .custom-radio {
  border-radius: 50%;
}
/*
.recap-container .choice-plan {
  border: 0 !important;
}
.recap-container .choice-plan label {
  display: block;
  border: 1px solid $dark-grey;
  margin: 0.5rem 0.2rem;
  padding: 0.5rem;
  box-sizing: border-box;
} 
.recap-container .custom-radio:checked + label {
  background-color: $dark-grey;
  color: $white;
}*/
.recap-container .custom-radio + label {
  color: $white;
  font-size: 0.95rem;
  display: block;
    margin: 0.7rem 0 0 0rem;
    padding: 0;
}
.recap-container .custom-radio + label span::before{
  width: 16px;
  height: 16px;
}
.recap-container .custom-radio + label span {
  height: 20px;
  width: 20px;
}
.recap-container .info {
  @include image(18px, 18px, 0 0 0 5px, '../../img/icons/customize__info.svg');
  display: inline-block;
  position: absolute;
  @include position(null, null, 0, null);
  z-index: 1;
  cursor: pointer;
}

// form

.form-container {
  @include boxSpecs($dark-grey, 100%, null, flex, null);
  max-width: 1400px;
  flex-wrap: wrap;
  margin: 4rem auto;
  padding: 3px;
  text-align: center;
  border: 1px solid $primary-color;
  box-shadow: 0px 0px 0px 5px $dark-grey;
}
.form-container > div {
  flex: 0 0 50%;
}
.form-container .form-image {
  background-size: cover;
}

.form-container .form-info {
  padding: 1rem 2rem;
  box-sizing: border-box;
}
.form-container .form-info h2 {
  @include typo(null, 1.5rem, $white, normal, null, null, null);
  margin: 0.1rem 0 1rem 0;
}
.form-container .form-info h5 {
  @include typo(null, 0.85rem, $white, normal, left, null, none);
  line-height: 1.3;
  margin: 0.1rem 0 0.5rem 0;
}
.form-info .form-text-box {
  background-color: $primary-color;
  margin: 2rem 0;
  padding: 1rem;
}
.form-info input {
  width: 100%;
  margin: 0.5rem 0 0 0;
  padding: 0.5rem;
  box-sizing: border-box;
}
.form-info select,
.form-info .visitors {
  background-color: #e8f0fe !important; 
}
.form-info .visitors label{
  color: $black !important;
}
.form-container .form-info .form-note {
  @include typo(null, 0.85rem, $primary-color, null, left, null, null);
  margin: 1.5rem 0 0.4rem 0;
}
.form-container .form-info .primary-button {
  background-color: $primary-color;
  color: $white;
  text-transform: uppercase;
  border: none;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  cursor: pointer;
  transition: 0.3s opacity;
}
.form-container .form-info .primary-button:hover {
  opacity: 0.8;
}
@media screen and (max-width: 960px) {
  .recap-container {
    flex-wrap: wrap;
  }
  .recap-container > div {
    flex: 0 0 100% !important;
  }
  .recap-container .planimetry-side {
    min-height: 600px;
  }
  .recap-container .text-box {
    text-align: center;
  }
}
@media screen and (max-width: $tablet) {
  .form-container > div {
    flex: 0 0 100%;
  }
  .form-container .form-image {
    height: 400px;
  }
}
