/* -------------------------
----------- COLORS ---------
--------------------------*/
$primary-color:     #d97c50;
$secondary-color:   #6f2e17;
//$tertiary-color:    #c5934c;
//$success-color:     #d8c9b6;
//$alert-color:       #cfd8d5;
$light-grey:        #666766;
$dark-grey:         #343232;
$white:             #fff;
$black:             #000;

// TEXT COLOR
$text-color:        #1a1a1a;

// BACKGROUND COLOR
$background-color:  #cfcfcf;

// LINK COLOR
$link-color: $white;

// BUTTONS COLOR
$primary-button-color: $dark-grey;