// BASE
@import '../scss/base/_reset.scss';
@import '../scss/base/_colors.scss';
@import '../scss/base/_typography.scss';
@import '../scss/base/_base.scss';

// COMPONENTS
@import '../scss/components/_header.scss';
@import '../scss/components/_nav.scss';
@import '../scss/components/_footer.scss';
@import '../scss/components/_buttons.scss';
@import '../scss/components/_calendar.scss';


// PAGES
@import '../scss/pages/_hp.scss';
@import '../scss/pages/_planimetry.scss';
@import '../scss/pages/_customize.scss';
@import '../scss/pages/_done.scss';

@import '../scss/pages/disclaimer';

// LOGOS
$primary-logo: '../../img/logos/primary-logo.svg';
$primary-logo--NEG: '../../img/logos/primary-logo--NEG.svg';

// LOGO HEADER
.header__logo {
  @include image(150px, 60px, 0 0 0 9%, $primary-logo--NEG);
  background-repeat: no-repeat;
}

// Typography project override
h2 {
  @include typo($secondary-font, 2rem, $white, bold, null, null, none);
  width: 100%;
  margin: 0.2rem 0;
}
h5 {
  @include typo(
    $primary-font,
    0.9rem,
    $primary-color,
    normal,
    null,
    0.05em,
    uppercase
  );
  width: 100%;
  margin: 0;
  padding: 0;
}
hr {
  @include boxSpecs($white, 10%, 3px, block, null);
  margin: 1rem 0;
  border: 0;
}

// custom radio
.custom-radio {
  display: none;
  + label {
    font-weight: normal;
    cursor: pointer;
    span {
      @include boxSpecs(null, 12px, 12px, inline-block, relative);
      vertical-align: middle;
      margin-right: 5px;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid $primary-color;

      &::before {
        content: '';
        @include boxSpecs($primary-color, 8px, 8px, block, absolute);
        @include position(2px, null, null, 2px);
        opacity: 0;
        border-radius: 50%;
      }
    }
  }
  &:checked + label span::before {
    opacity: 1;
  }
}
