header {
  @include boxSpecs($dark-grey, 100%, $header-height, flex, fixed);
  @include position(0, null, null, 0);
  font-family: $primary-font;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  a {
    @include typo(null, 0.9rem, null, bold, null, null, null);
    padding: 0 2rem;
    position: relative;
    span {
      color: $link-color;
    }
  }
}

.header__back:before,
.header__contact-us:before {
  content: '';
  @include image(190px, 56px, 0, '../../img/icons/header__back.svg');
  @include boxSpecs(null, null, null, inline-block, absolute);
  @include position(-12px, null, null, 0);
}
.header__contact-us:before {
  @include image(48px, 48px, 0, '../../img/icons/header__contact.svg');
  @include position(-20px, null, null, -30px);
}

@media screen and (max-width: $tablet) {
  header .header__logo {
    width: 100px;
    margin: 0 0 0 20%;
  }
  header a,
  header.header__back {
    padding: 0 1rem;
  }
  a.header__contact-us {
    @include position(1rem, null, null, null);
    text-align: center;
  }
  header .header__back:before {
    @include position(-22px, null, null, -100px);
  }
  header .header__contact-us:before {
    @include position(-40px, null, null, 60px);
    height: 35px;
  }
}
