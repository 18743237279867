// CONTENT SPACER
$sm-space: 1rem;
$md-space: $sm-space*2;
$lg-space: $sm-space*3;

html{
  scroll-behavior: smooth;
}
body{
    background: $white;
}

$header-height: 90px;
$nav-height: 35px;
$footer-height: 30px;

// MAIN CONTAINER
.main-container {
    min-height: calc(100vh - #{$header-height} - #{$nav-height} - #{$footer-height});
    margin-top: calc(#{$header-height} + #{$nav-height});
    font-family: $secondary-font;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

// ALIGN
// Position absolute - center
%center-absolute-X{
    left: 50%;
    transform: translateX(-50%);
}
%center-absolute-Y{
    top: 50%;
    transform: translateY(-50%);
}
%center-absolute-XY{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// IMAGES
@mixin image($width, $height, $margin, $image-path) {
    background-image: url($image-path);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: $width;
    height: $height;
    margin: $margin;
    display: block;
  }

  @mixin typo($family, $size, $color, $weight, $align, $spacing, $transform) {
    font-family: $family;
    font-size: $size;
    color: $color;
    font-weight: $weight;
    text-align: $align;
    letter-spacing: $spacing;
    text-transform: $transform;
  }

  @mixin boxSpecs($backgroundColor, $width, $height, $display, $position) {
    background-color: $backgroundColor;
    height: $height;
    width: $width; 
    display: $display;
    position: $position;
  }

  @mixin position ($top, $right, $bottom, $left) {
    top: $top; 
    right: $right;
    bottom: $bottom;
    left: $left;
  }

// RESPONSIVE BREAKPOINTS
$small-desktop: 991px;
$tablet: 768px;
$mobile: 480px;


// RESPONSIVE VISIBILITY

@media screen and (max-width: 1281px) {
    .lg-hidden {
      display: none !important;
    }
  }
  
  @media screen and (max-width: 991px) {
    .md-hidden {
      display: none !important;
    }
  }
  
  @media screen and (max-width: 768px) {
    .sm-hidden {
      display: none !important;
    }
  }
  
  @media screen and (max-width: 480px) {
    .xs-hidden {
      display: none !important;
    }
  }