// PRIMARY FONT IMPORT - Swiss 721 Condensed
  @font-face {
    font-family: 'Swis721 Cn BT';
    src: url('../../fonts/Swiss721Condensed/Swiss721BT-BoldCondensed.woff2') format('woff2'),
        url('../../fonts/Swiss721Condensed/Swiss721BT-BoldCondensed.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Swis721 Cn BT';
    src: url('../../fonts/Swiss721Condensed/Swiss721BT-ItalicCondensed.woff2') format('woff2'),
        url('../../fonts/Swiss721Condensed/Swiss721BT-ItalicCondensed.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Swis721 Cn BT';
    src: url('../../fonts/Swiss721Condensed/Swiss721BT-RomanCondensed.woff2') format('woff2'),
        url('../../fonts/Swiss721Condensed/Swiss721BT-RomanCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// SECONDARY FONT IMPORT - Open Type CFF Std
@font-face {
  font-family: 'Orgon';
  src: url('../../fonts/OpenType/Orgon-Light.woff2') format('woff2'),
      url('../../fonts/OpenType/Orgon-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Orgon';
  src: url('../../fonts/OpenType/Orgon-Bold.woff2') format('woff2'),
      url('../../fonts/OpenType/Orgon-Bold.woff2') format('woff');
  font-weight: bold;
  font-style: normal;
}

// FONT FAMILY
$primary-font: 'Swis721 Cn BT';
$secondary-font: 'Orgon';


// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color: $text-color;
$paragraph-size: 1.2rem;
$paragraph-line-height: 1.5;
$paragraph-margin: 20px;

// Heading Styles
$heading-color: $primary-color;
$heading-size: 2rem;
$heading-line-height: 1.25;
$heading-margin: $paragraph-margin * 1.2;

// Weight
$normal: 400;
$semibold: 500;
$bold: 700;

html,
body {
  font-size: $base-font-size;
  font-family: $primary-font;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Heading
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: $heading-margin * 1.5;
  color: $heading-color;
  line-height: $heading-line-height;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: $heading-size;
  margin-bottom: $heading-margin;
}
h2 {
  font-size: $heading-size / 1.3;
  margin-bottom: $heading-margin / 1.3;
}
h3 {
  font-size: $heading-size / 1.5;
  margin-bottom: $heading-margin / 1.5;
}
h4 {
  font-size: $heading-size / 1.7;
  margin-bottom: $heading-margin / 1.7;
}
h5 {
  font-size: $heading-size / 1.8;
  margin-bottom: $heading-margin / 1.8;
}
h6 {
  font-size: $heading-size / 2;
  margin-bottom: $heading-margin / 2;
}

// Paragraphs
p {
  margin-bottom: $paragraph-margin;
  font-size: $paragraph-size;
  line-height: $paragraph-line-height;
  color: $paragraph-color;

  &:last-of-type {
    margin-bottom: 0;
  }
}

// Links
a {
  color: $link-color;
  transition: all 0.2s ease;
  text-decoration: none;

  &:hover {
    color: darken($link-color, 20%);
  }
}

// Disclaimer
$disclaimer-font-size: 0.7rem;
$disclaimer-line-height: 1.5;
$disclaimer-link-size: 0.8rem;


// Misc Styles
em,
i,
.italic {
  font-style: italic;
}
strong, b, .bold {
  font-weight: 700;
}
.center-text{
  text-align: center;
}