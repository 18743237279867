// NAV
nav {
  @include typo($secondary-font, null, null, bold, null, null, null);
  @include boxSpecs(rgba($primary-color, 0.6), 100%, $nav-height, flex, fixed);
  @include position(90px, null, null, 0);
  z-index: 9;
}
nav ul {
  @include boxSpecs(null, 100%, 100%, flex, null);
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
nav ul li {
  @include boxSpecs(null, null, 100%, null, relative);
  list-style-type: none;
  text-align: center;
  flex: 0 0 25%;
}

nav ul li a {
  @include boxSpecs(null, 100%, calc(100% - 6px) !important, block, null);
  margin: 3px 0 !important;
  line-height: 1.8;
  &.active {
    background-color: $primary-color;
  }
}

nav ul li div {
  @include boxSpecs(null, 100%, calc(100% - 6px) !important, block, null);
  margin: 3px 0 !important;
  line-height: 1.8;
  &.active {
    background-color: $primary-color;
  }
}

nav ul li div.inactive {
  color: floralwhite;
}

nav ul li span.page-number {
  font-size: 1.5rem;
  line-height: 1.2;
  position: absolute;
  @include position(null, null, null, 2rem);
}
nav ul li:not(:last-child) a.active:after {
  content: '';
  @include boxSpecs(null, 0, 0, null, absolute);
  @include position(3px, -29px, null, null);
  border-style: solid;
  border-width: 15px 0 14px 29px;
  border-color: transparent transparent transparent $primary-color;
}
nav ul li a.nav--home,
nav ul li a.nav--done {
  @include image(inherit, inherit, 0, '../../img/icons/nav__home.svg');
  background-size: 25px 25px;
}
nav ul li a.nav--done {
  background-image: url(../../img/icons/nav__done.svg);
}
nav ul li a.active.nav--done {
  background-image: url(../../img/icons/nav__done--active.svg);
}
nav ul li div.inactive.nav--done {
  background-image: url(../../img/icons/nav__done--active.svg);
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 1281px) {
  nav ul li a span.page-number {
    position: static;
  }
}
