.customize-container {
  font-family: $secondary-font;
  width: 100%;
  display: flex;
}

.furnishings,
.options-container {
  flex: 0 0 25%;
  padding: 0 3rem 2rem 3rem;
  box-sizing: border-box;
}
.furnishings .info {
  @include image(35px, 35px, 0, '../../img/icons/customize__chf.svg');
  @include position(1rem, 1rem, null, null);
  position: absolute;
}
.furnishings .info .price-tooltip {
  @include typo($secondary-font, 1rem, $white, bold, center, null, none);
  @include boxSpecs(rgba($primary-color, 0.9), 110px, null, block, absolute);
  @include position (-47px, null, null, 40%);
  padding: 0.5rem 1.5rem;
  //border: 1px solid #343232;
  //box-shadow: 0 0 0 3px #d97c50;
  transform: translateX(-50%);
  border-radius: 5px;
  z-index: 99;
}
.furnishings .info .price-tooltip:before {
  content: '';
  @include position (35px, null, null, 50%);
  @include boxSpecs(null, 0, 0, block, absolute);
  border-style: solid;
  border-width: 7px 0 7px 10px;
  border-color: transparent transparent transparent rgba($primary-color, 0.9);
  transform: rotate(90deg) translateX(-50%);
}
.furnishing-box:not(.active) .price-tooltip{
  display: none;
}
.furnishings .furnishing-box {
  @include boxSpecs($dark-grey, null, null, null, relative);
  padding: 1rem;
  margin: 1rem 0;
  border: 3px solid $background-color;
  cursor: pointer;
  &.active {
    box-shadow: 0 0 7px 2px $light-grey;
    left: 0.5rem;
    &:after {
      content: '';
      @include position(null, -30px, null, null);
      @include boxSpecs(null, 0, 0, null, absolute);
      border-style: solid;
      border-width: 30px 0 30px 20px;
      border-color: transparent transparent transparent $primary-color;
      @extend %center-absolute-Y;
      z-index: 2;
    }
  }
  &:not(.active) {
    background-color: lighten($light-grey, 55%);
    min-height: 100px;
  }
  &:not(.active) .details,
  &:not(.active) .price {
    visibility: hidden;
    display: none;
  }
  &:not(.active) h2,
  &:not(.active) h5 {
    color: lighten($dark-grey, 50%);
  }
  &:not(.active) hr {
    background-color: lighten($dark-grey, 50%);
  }
  &:not(.active) .info {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}
.customize-container .furnishings > h5 {
  @include typo(null, null, $dark-grey, bold, center, null, null);
  margin: 1rem 0 0 0;
}
.customize-container hr {
  width: 10%;
  margin: 0.5rem 0 1rem 0;
}
.customize-container h2 {
  @include typo(null, 1.8rem, $white, null, null, null, null);
  margin: 0;
}
.customize-container h5 {
  @include typo(null, 0.9rem, null, normal, null, null, null);
  margin: 0;
}
.customize-container .furnishing-box .price {
  @include boxSpecs($primary-color, 80px, 80px, flex, absolute);
  @include position(null, 1rem, 3rem, null);
  @include typo(null, 0.7rem, $white, null, center, null, null);
  padding: 0.2rem;
  box-sizing: border-box;
  border-radius: 50%;
  align-items: center;
  align-content: center;
  display: grid;
}
.customize-container .furnishing-box .price span {
  font-size: 1rem;
  line-height: 0.9;
  margin-top: 0.3rem;
}
.customize-container .furnishing-box .details {
  @include boxSpecs(null, 60%, null, inline-block, null);
  @include typo(null, 0.8rem, $white, null, center, null, null);
  margin: 3rem 0 0 0;
  padding: 0.5rem;
  border: 1px solid $primary-color;
}

.customize-container .customize-image-container {
  @include image(100%, 95%, 0, null);
  max-height: calc(
    97vh - #{$header-height} - #{$nav-height} - #{$footer-height}
  );
  background-size: cover;
  flex: 0 0 50%;
  margin: 1rem 0;
  position: relative;
}
.customize-container .customize-image-container .primary-button {
  @include position(null, null, 2rem, null);
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  @extend %center-absolute-X;
}

.customize-container .options-container .options-box {
  @include boxSpecs($dark-grey, null, null, null, relative);
  margin: 1rem 0;
  padding: 0.7rem 1rem;
  box-sizing: border-box;
  border: 3px solid $background-color;
  &.active {
    box-shadow: 0 0 7px 2px $light-grey;
    @include position(null, 0.5rem, null, null);
    &:after {
      content: '';
      @include boxSpecs(null, 0, 0, null, absolute);
      @include position(null, null, null, -30px);
      border-style: solid;
      border-width: 30px 0 30px 20px;
      border-color: transparent transparent transparent $primary-color;
      @extend %center-absolute-Y;
      transform: translateY(-50%) rotate(180deg);
      z-index: 2;
    }
  }
}
.customize-container .options-container .options-box > div {
  @include boxSpecs(null, 100%, null, flex, null);
  cursor: pointer;
  &.active {
    h5 {
      color: $white;
    }
    span {
      border: 2px solid $white;
    }
  }
}
.customize-container .options-container .options-box h5 {
  line-height: 2.2;
}
.customize-container .options-container .options-box > h5 {
  line-height: 1.7;
}
.customize-container .options-container .options-box span {
  @include boxSpecs($primary-color, null, 35px, inline-block, null);
  @include image(null, 35px, 0, null);
  flex: 0 0 35px;
  margin: 0 0.5rem 0.5rem 0;
  border: 2px solid $dark-grey;
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0 0 0 1px white;
}

@media screen and (max-width: 1024px) {
  .customize-container {
    flex-wrap: wrap;
  }
  .customize-container .furnishings,
  .customize-container .customize-image-container,
  .customize-container .options-container {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .customize-container .furnishings > h5 {
    @include position(0, null, null, null);
    text-align: left;
    position: absolute;
  }
  .customize-container .furnishings > h5 br {
    display: none;
  }
  .customize-container .furnishings {
    position: relative;
    padding: 4rem 3rem 2rem 3rem;
    box-sizing: border-box;
  }
  .customize-container .furnishing-box .price {
    bottom: 2rem;
    right: 1.5rem;
}
  .customize-container .customize-image-container {
    min-height: 500px;
  }
  .customize-container .furnishings .furnishing-box {
    margin: 0 1rem 0 0;
    flex: 0 0 28%;
    min-width: 250px;
  }
  .customize-container .furnishing-box .details {
    margin: 0;
  }
  .customize-container .options-container {
    margin: 1rem 1rem 3rem 1rem;
    padding: 0;
  }
  .customize-container .options-container .options-box {
    margin: 0 1rem 0 0;
    flex: 0 0 50%;
    min-width: 300px;
  }
  .customize-container .furnishings .furnishing-box.active,
  .customize-container .options-container .options-box.active {
    @include position(null, null, null, 0);
  }
  .customize-container .options-container .options-box:not(.active) {
    background-color: lighten($light-grey, 55%);
  }
  .customize-container .options-container .options-box:not(.active) > div {
    visibility: hidden;
  }
  .customize-container .options-container .options-box:not(.active) > h2,
  .customize-container .options-container .options-box:not(.active) > h5 {
    color: lighten($dark-grey, 50%);
  }
  .customize-container .options-container .options-box:not(.active) > hr {
    background-color: lighten($dark-grey, 50%);
  }
  .customize-container .furnishings .furnishing-box.active:after,
  .customize-container .options-container .options-box.active:after {
    content: none;
  }
}
