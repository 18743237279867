.info-container{
  margin: calc(90px + 35px) auto 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;
  width: calc(750px + 6rem);
  max-width: 100%;
  align-content: flex-start;
}

.info-container h2{
  color: $primary-color;
  margin: 3rem 0 0 0;
}