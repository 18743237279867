  // PRIMARY BUTTON
  .primary-button {
    @include typo($primary-font, 0.9rem, $white, bold, center, null, null);
    background-color: $primary-button-color;
    padding: 1rem 2.5rem;
    border-radius: 0;
    transition: background ease-in 0.3s;
    position: relative;
    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }